<template>
	<div class="aqt_list_lists aqt_no_su">
		<div
			v-for="(item,index) in dataLists"
			:key="index"
			class="aqt_list_lists_one"
			@click="onLineClick(item)">
			<div class="aqt_top aqt_ce_ve">
				<img v-lazy="item.img" alt />
				<div v-show="item.annexcount>0" class="label">PDF</div>
			</div>
			<div class="aqt_center">
				<!-- 蒙语标题 -->
				<div class="aqt_title" v-if="item.title">
					<p class="titlefont" :class="item.tclassname">{{(item.title=='undefined'?'':item.title).slice(0,100)|U2M}}</p>
				</div>
				<!-- 汉语标题 -->
				<div class="aqt_title" v-if="['/newslist'].includes($route.path)">
					<p class="aqt_lang_mn1 contentfonts1">{{item.title_zh?item.title_zh:''}}</p>
				</div>
				<div class="aqt_zhengzhuang" v-if="!['/newslist'].includes($route.path)">
					<img src="../assets/img/info_people.png" alt />
					<p class="contentfont contentfonts1" :class="item.aclassname">{{item.author?item.author:'ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ'|U2M}}</p>
				</div>

				<div class="aqt_zhengzhuang" v-if="!['/newslist'].includes($route.path)">
					<img src="../assets/img/info_book.png" alt />
					<p class="contentfont contentfonts1"  :class="item.pclassname">{{item.press?item.press:'ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ'|U2M}}</p>
				</div>
				<div class="aqt_zhengzhuang" v-if="!['/newslist'].includes($route.path)">
					<img src="../assets/img/pubulish.png" alt />
					<p class="aqt_lang_mn contentfont contentfonts1">{{item.publish_date?item.publish_date:'ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ'|U2M}}</p>
				</div>
			</div>
		</div>
		<div v-if="isLoading && !dataLists.length"  class="nomassage">
			<p class="aqt_lang_mn titlefont">{{'ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ！'|U2M}}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		dataList: {
			type: [Array],
			default: [],
		},
		isLoading: {
			type: [Boolean],
			default: false,
		},
	},
	data() {
		return {
			dataIndex: -1,
			dataLists: [],
		};
	},
	methods: {
		onLineClick(data) {
			const _this = this;
			_this.$emit("on-line-click", data);
			return;
		},
	},
	mounted() {},
	watch: {
		dataList: function (val) {
			this.dataLists = this.dataList;
		},
	},
	computed: {},
};
</script>

<style lang="less">
.aqt_list_lists {
	height: 100%;
	display: flex;

	.contentfonts1 {
		font-size: 1.6rem;
	}

	.nomassage {
		padding: 0 1rem;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		display: -ms-flexbox;
		display: flex;
		font-size: 2rem;
	}

	.aqt_list_lists_one {
		// width: 12rem;
		width: 120px;
		height: 100%;
		background-color: #ffffff;
		margin-right: 0.2rem;

		.aqt_top {
			// height: 8rem;
			height: 127px;
			width: calc(100% - 16px);
			margin: 8px auto 0;
			position: relative;
			// border-radius: 3px;
			box-shadow: rgb(136, 136, 136) 1px 1px 6px;
			overflow: hidden;
			.label {
				line-height: 24px;
				height: 20px;
				height: 20px;
				background: red;
				color: #fff;
				font-size: 12px;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
				padding: 0 15px;
				-webkit-transform-origin: left bottom;
				-moz-transform-origin: left bottom;
				transform-origin: left bottom;
				-webkit-transform: translate(29.29%, -100%) rotate(45deg);
				-moz-transform: translate(29.29%, -100%) rotate(45deg);
				transform: translate(29.29%, -100%) rotate(45deg);
				text-indent: 0;
			}
			img {
				height: inherit;
				width: 100%;
				display: inline-flex;
			}
			img[lazy="loading"]{
				height: auto!important;
			}
			.playdiv {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(0, 52, 97, 0.4);
				background-size: 40%;
				background-repeat: no-repeat;
				background-position: center center;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-pack: center;
				justify-content: center;
			}
		}

		.aqt_center {
			// height: calc(100% - 8rem);
			height: calc(100% - 135px);
			width: 100%;
			padding-top: 1rem;
			display: flex;
			.aqt_lang_mn1 {
				writing-mode: vertical-lr;
			}
			.aqt_title {
				// width: calc(100% - 8.8rem);
				width: 3.4rem;
				height: calc(100% - 1rem);
				color: #000000;
				font-size: 1.8rem;
				margin: 0 0.5rem;

				p {
          height: calc(100% - 1rem);
					white-space: nowrap;
					-o-text-overflow: ellipsis;
					text-overflow: ellipsis;
					overflow: hidden;
					margin: 0 auto;
				}
			}

			.aqt_con {
				height: calc(100% - 1rem);
				color: #888888;
				font-size: 1.6rem;
				overflow: hidden;
				width: 5.6rem;
				line-height: 1.9rem;

				p {
					margin: 0 auto;
				}
			}

			.aqt_zhengzhuang {
				height: calc(100% - 3.4rem);
				width: calc(calc(100% - 3.4rem) / 2);
				color: #888888;
				font-size: 1.2rem;
				// margin: 0 .5rem;
				white-space: nowrap;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				overflow: hidden;

				img {
					width: 1.6rem;
					height: 1.6rem;
					margin-bottom: 0.5rem;
				}
			}
		}
	}
}
</style>
